<template>

        <div class="ui p-half clearing red segment">
            <!--<div>
                <h2 class="ui left floated header m-0"> Liste des Assurances </h2>
                <div class="ui right floated primary button" @click="newAssurance"> Nouveau </div>
            </div>-->
            <div class="downloadTab">
                <h3 class="ui left floated header m-0"> Liste des Dossiers </h3>
                <span class="right floated flex items-end" @click="newFolder">
                <svg
                        width="20"
                        height="20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                >
                    <g clip-path="url(#clip0)">
                        <path
                                d="M17.071 2.929A9.935 9.935 0 0010 0a9.935 9.935 0 00-7.071 2.929A9.935 9.935 0 000 10a9.935 9.935 0 002.929 7.071A9.935 9.935 0 0010 20a9.935 9.935 0 007.071-2.929A9.935 9.935 0 0020 10a9.935 9.935 0 00-2.929-7.071zM10 18.438c-4.652 0-8.438-3.786-8.438-8.438 0-4.652 3.786-8.438 8.438-8.438 4.652 0 8.438 3.786 8.438 8.438 0 4.652-3.786 8.438-8.438 8.438zm.781-9.22h3.516v1.563H10.78v3.515H9.22v-3.515H5.703V9.217H9.22V5.702h1.562v3.516z"
                                fill="#0953AA"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0">
                            <path fill="#fff" d="M0 0h20v20H0z"/>
                        </clipPath>
                    </defs>
                </svg>
                Ajouter
            </span>
            </div>

            <div class="ui accordion">
                <div class="title">
                    <i class="dropdown icon"></i>
                    <u>Filtrer par:</u>
                </div>
                <div class="content">
                    <div class="ui segment">
                        <div class="ui equal width form">
                            <div class="two fields">
                                <div class="field">
                                    <label>Numero Dossier</label>
                                    <input type="text" placeholder="Numero dossier" v-model="searchQuery.numero" @keyup.enter="getFolders">
                                </div>
                                <div class="field">
                                    <label>Dispatcher</label>
                                    <v-select :options="dispatcher_list" v-model="selected_dispatcher" @input="getFolders"
                                              label="username" style="text-transform: capitalize" placeholder="Dispatcher">
                                    </v-select>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

            <table class="ui selectable celled table">
                <thead>
                <tr>
                    <th>Actions</th>
                    <th @click="sortData('numero')">
                        N° dossier
                        <i v-if="sort.numero" class="long arrow alternate icon float-right"
                           :class="sort.numero === 1 ? 'up' : 'down'"></i>
                    </th>
                    <th>Immatriculation</th>
                    <th>Date de création</th>
                    <th> Crée par </th>
                    <th> Dispatcher </th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="!folder_list.length"><td colspan="99"> Pas de données disponibles </td> </tr>
                <tr v-for="folder in folder_list">
                    <td class="collapsing">
                        <div @click="edit(folder)" style="cursor: pointer">
                            <i class="ui edit icon"></i>Edit
                        </div>
                    </td>
                    <td class="danger-cell"> {{ folder.numero || dash}}</td>
                    <td> {{ folder.vehicule ? (folder.vehicule.n_immat || dash) : dash}}</td>
                    <td> {{ $store.getDate(folder.date_creation, 'DD-MM-YYYY') || dash}}</td>
                    <td> {{ folder.user.username || dash}}</td>
                    <td> {{ folder.dispatcher ? (folder.dispatcher.username || dash) : dash }}</td>
                </tr>
                </tbody>

                <tfoot>
                    <tr>
                        <th colspan="99">
                            <div>
                                <pagination :limit="limit" :current_page="page" :count="count" @setPage="setPage" slot="pagination"/>
                            </div>
                        </th>
                    </tr>
                </tfoot>

            </table>

        </div>

</template>

<script>
    import pagination from "@/components/pagination.vue";
    export default {
        name: "folderList",
        components:{
            pagination
        },
        data() {
            return {
                folder_list: [],
                page: 1,
                limit: 10,
                count: 0,
                dash: '--------',
                selected_dispatcher: {username:'tous'},
                dispatcher_list: [{username:'tous'}],
                searchQuery:{
                    numero:null
                },
                sort: {numero: 1}
            }
        },
        methods: {
            getFolders() {
                log('getFolders');
                let match = {type: 'dossier'}
                if(this.searchQuery.numero){
                    match.numero = {$regex: this.searchQuery.numero, $options:'i'}
                }
                if(this.selected_dispatcher && this.selected_dispatcher.username !== 'tous'){
                    match['dispatcher._id'] = this.selected_dispatcher._id
                }
                let data = {
                    collection: this.$SHARED.collections.documents,
                    query: match,
                    options: {
                        page: parseInt(this.page),
                        limit: parseInt(this.limit)
                    }
                };
                if (!_.isEmpty(this.sort)) {
                    data.options.sort = JSON.parse(JSON.stringify(this.sort))
                }

                this.$store.requestFind(data).then(ar => {
                    this.folder_list = ar.docs;
                    this.count = ar.count;
                }, code => {
                    this.$notify({
                        group: 'user-message',
                        type: 'error', // warn , error, success, info
                        text: this.$SHARED.messages.technical_error
                    });
                })
            },
            sortData(field) {
                if (Object.keys(this.sort)[0] !== field) {
                    this.sort = {};
                    this.sort[field] = -1
                }

                if (this.sort[field] === 1) {
                    this.sort[field] = -1
                } else {
                    this.sort[field] = 1
                }

                this.getFolders();
            },
            getDispatcher() {
                console.log('getDispatcher');
                const data = {
                    query: {type: 'dispatcher'},
                    options: {page: 1, limit: -1}
                };
                this.$store.dispatchAsync(this.$SHARED.services.user.list, data).then(ar => {
                    this.dispatcher_list = this.dispatcher_list.concat(ar.docs);
                }, code => {
                    this.$notify({
                        group: 'user-message',
                        type: 'error', // warn , error, success, info
                        text: this.$SHARED.messages.technical_error
                    });
                })
            },
            newFolder(){
                this.$router.push({name: 'folderCreate'});
            },
            edit(folder_prop){
                this.$router.push({name: 'folderEdit_dispatcher', params:{folder_prop}});
            },
            setPage(page){
                this.page = page;
                this.getFolders();
            },
        },
        mounted() {
            this.getFolders();
            $('.ui.accordion').accordion({exclusive:true});
            this.getDispatcher();
        }
    }
</script>

<style scoped>

</style>